import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../../Components/Layout";
import Portada from "../../utils/Portada";
import Mobile from "../../Assets/portada_servicios_mobile.webp";
import Coclear from "../../Components/Coclear";
import Help from "../../Components/Help";
import Desktop from "../../Assets/portada_servicios_desktop.webp";
import LinearTop from "../../Components/LinearTop";
import BoxContainer from "../../utils/BoxContainer";
import CentroAuditivo from "../../Components/CentroAuditivo";
import Check from "../../Assets/check_square.png";
import Esteto from "../../Assets/esteto_check.png";
import Acufeno from "../../Assets/acufeno_check.png";
import Linear from "../../Components/Linear";
import useWindowSize from "../../hooks/useWindowSize";
const StyledHorizontal = styled.div`
  .cards__ {
    display: flex;
    overflow-x: scroll;

    padding: 10px;
    margin-left: 20px;
    .__info__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 5px;
      width: 200px;
      img {
        width: 120px;
        margin: 0 20px;
      }
      h1 {
        text-align: center;
        font-size: 14px;
        margin: 16px 0;
        height: 50px;
        color: var(--strong-blue);
        font-family: "lato_bold";
        font-weight: 600;
      }
      hr {
        border: 1px solid var(--demon-blue);
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  .container_box {
    color: var(--purple);
    .with_check {
      img {
        width: 50px;
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
    }
  }
  .title {
    h1 {
      font-family: "lato_bold";
      font-size: 24px;
      color: var(--strong-blue);
      padding: 0 20px;
    }
    p {
      font-family: "lato_regular";
      font-size: 16px;
      color: var(--strong-blue);
      padding: 0 20px;
      color: var(--gray-strong);
      margin: 20px 0;
    }
  }
  .ic_box{
    padding-bottom: 100px;
  }
  @media (min-width: 1200px) {
    .container_box {
      max-width: 40%;
    }
    .cards__ {
      padding-left: 260px;
      justify-content: center;
    }
  }
  .title {
    margin-top: 40px;
    h1 {
      font-size: 30px;
      margin-left: 40px;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      margin-left: 40px;
    }
  }
  @media (min-width: 1400px) {
    padding-left: 00px;
  }
`;

const StyledNosotros = styled.div``;
const Id = ({location}) => {
  const [hasMounted, setHasMounted] = useState(false);
  const idParams = location?.state?.data?.id;
  console.log(idParams)
  const [cardId, setCardId] = useState(null);
  const [title, setTitle] = useState(null);
  const windowSize = useWindowSize();
  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() =>{
    setCardId(idParams)
  
  }, []);

  useEffect(() =>{
    windowSize < 1200 ? 
        window.scrollTo(0, 1000) :  window.scrollTo(0, 1000)
    })
  return hasMounted ? (
    <StyledNosotros>
      <Layout>
        <Portada imageDesktop={Desktop} imageMobile={Mobile} title="Servicios"/>
        <Coclear />
        {cardId !== 8 ? <LinearTop /> : null}
        <div id="aquivamos"/> 
    <StyledHorizontal>
      <div className="cards__">
        {iconsArray.map((e, idx) => {
          return (
            <div className="__info__card">
              <img
                key={idx}
                src={cardId === e.id ? e.blue : e.white}
                alt="icon_image"
                onClick={() => {
                  setCardId(e.id);
                  setTitle(e.title);
                }}
              />
              <h1>{e.title}</h1>
              <hr />
            </div>
          );
        })}
      </div>
      <div>
        {cardId  === 1 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <p>
                En el Centro Auditivo IMON nuestra prioridad es que todos puedan
                sentir el placer de escuchar y vivir sin limitaciones auditivas.
              </p>
              <p>
                La adquisición de un aparato auditivo requiere de la mejor
                tecnología y de un proceso personalizado, adecuado a las
                necesidades de cada persona, por eso ofrecemos:
              </p>
              <CentroAuditivo />
            </div>
          </>
        ) : null}
        {cardId  === 2 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <BoxContainer>
                <div className="with_check">
                  <img src={Check} alt="check_icon" /> Diagnosticamos los
                  padecimientos que causan problemas de vértigo y desequilibrio.
                </div>
              </BoxContainer>
            </div>
          </>
        ) : null}
        {cardId  === 3 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <p>
                El acúfeno es escuchar sonidos o ruidos que parecen provenir del
                oído o se sienten “dentro de la cabeza”.
              </p>
              <BoxContainer>
                <div className="with_check">
                  <img src={Acufeno} alt="check_icon" /> Su tratamiento puede
                  ser difícil, pero con un diagnóstico adecuado y profesional,
                  se pueden identificar las causas que necesitan tratamiento.
                </div>
              </BoxContainer>
            </div>
          </>
        ) : null}
        {cardId  === 4 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <p>
                El vértigo es uno de los principales motivos de consulta en
                personas de mayor edad. Se trata de una alucinación de
                movimiento que hay que diferenciar del desequilibrio y del
                mareo. No es un diagnóstico, sino un síntoma y el tratamiento
                óptimo depende de identificar correctamente la causa.
              </p>
            </div>
          </>
        ) : null}
        {cardId  === 5 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <p>
                La pérdida auditiva es una consecuencia natural; sin embargo, si
                no es tratada puede ser dañino para el paciente: puede alienar
                de sus seres queridos, dejar de hacer actividades y aumentar el
                riesgo de demencia senil.
              </p>
              <BoxContainer>
                <div className="with_check">
                  <img src={Check} alt="check_icon" />
                  En el Centro Auditivo IMON contamos con auxiliares auditivos
                  de la mayor tecnología y calidad. Además, ofrecemos
                  calibraciones, audiometrías y consultas médicas.
                </div>
              </BoxContainer>
            </div>
          </>
        ) : null}
        {cardId  === 6 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <p>
                Es una enfermedad que aparece cuando el hueso que aloja al oído
                interno se tiende a reblandecer y osificar en forma alternante.
                Produce una pérdida de audición que frecuentemente es tratable
                con cirugía.
              </p>
            </div>
          </>
        ) : null}
        {cardId  === 7 ? (
          <>
            <div className="title">
              <h1>{title}</h1>
              <BoxContainer>
                <div className="with_check">
                  <img src={Esteto} alt="check_icon" /> Muchos padecimientos
                  requieren de cirugía para su resolución óptima. Esto incluye
                  tumores, infecciones, secuelas de accidentes, etc.
                </div>
              </BoxContainer>
            </div>
          </>
        ) : null}
         {cardId  === 8 ? (
          <>
            <div className="title ic_box">
              <h1>{title}</h1>
                <div className="with_check">
                <Linear />
                </div>
    
            </div>
          </>
        ) : null}
        {cardId  === 9 ? (
          <>
            <div className="title ic_box">
              <h1>{title}</h1>
  
                <p >
                Si un bebe no está aprendiendo a hablar, podría tener un problema auditivo. Las soluciones varían entre terapias y tratamientos médicos. Es importante tener un buen diagnóstico antes de tomar cualquier decisión.
                </p>
     
    
            </div>
          </>
        ) : null}
      </div>
    </StyledHorizontal>

        <Help />
      </Layout>
    </StyledNosotros>
  ) : null;
};

export default Id;


const iconsArray = [
  {
    blue: require("../../Assets/products/imon_blue_card.png").default,
    white: require("../../Assets/products/imon_white_card.png").default,
    title: "Centro auditivo IMON",
    id: 1,
  },
  {
    blue: require("../../Assets/products/equilibrio_blue_card.png").default,
    white: require("../../Assets/products/equilibrio_white_card.webp").default,
    title: "Estudios funcionales de audicion y equilibrio",
    id: 2,
  },
  {
    blue: require("../../Assets/products/acufeno_blue_card.png").default,
    white: require("../../Assets/products/acufeno_white_card.webp").default,
    title: "Acúfeno (tinnitus)",
    id: 3,
  },
  {
    blue: require("../../Assets/products/vertigo_blue_card.png").default,
    white: require("../../Assets/products/vertigo_white_card.webp").default,
    title: "Vértigo",
    id: 4,
  },
  {
    blue: require("../../Assets/products/tercera_blue_card.png").default,
    white: require("../../Assets/products/tercera_white_card.webp").default,
    title: "Tercera edad",
    id: 5,
  },
  {
    blue: require("../../Assets/products/otoesclerosis_blue_card.png").default,
    white: require("../../Assets/products/otoesclerosis_white_card.webp").default,
    title: "Otoesclerosis",
    id: 6,
  },
  {
    blue: require("../../Assets/products/cirugia_blue_card.png").default,
    white: require("../../Assets/products/cirugia_white_card.webp").default,
    title: "Cirugía de oído",
    id: 7,
  },
  {
    blue: require("../../Assets/products/coclear_blue_card.png").default,
    white: require("../../Assets/products/coclear_white_card.png").default,
    title: "Implante coclear",
    id: 8,
  },
  {
    blue: require("../../Assets/products/infancia_blue_card.png").default,
    white: require("../../Assets/products/infancia_white_card.png").default,
    title: "Infancia y niñez",
    id: 9,
  },
];